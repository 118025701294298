import React from 'react'
import Layout from "../../components/Layout"
import SEO from '../../components/seo'
import styles from './styles.module.scss'

const Contact = () => {

  return (
      <Layout>
        <SEO title="Contact | Academic Info" />

        <section className={styles.contact}>
          <h1 className={styles.pageTitle}>
            Contact
          </h1>

          <form
              method="post"
              netlify-honeypot="test-field"
              data-netlify-recaptcha="true"
              data-netlify="true"
              name="contact"
          >
            <input type="hidden" name="test-field" />
            <input type="hidden" name="form-name" value="contact" />

            <p>
              <label>Your Name
                <input type="text" name="name"  tabIndex={1} />
              </label>
            </p>

            <p>
              <label>Your Email *
                <input type="email" name="email" required tabIndex={2} />
              </label>
            </p>

            <p>
              <label>Subject *
                <input className={styles.subject} type="text" name="subject" required tabIndex={3} />
              </label>
            </p>

            <p>
              <label>Message *
                <textarea name="message" required rows={6} tabIndex={4} />
              </label>
            </p>

            <p>
              <div data-netlify-recaptcha="true"></div>
            </p>

            <p>
              <button type="submit" tabIndex={5}>Send</button>
            </p>
          </form>
        </section>
      </Layout>
  );
};

export default Contact
